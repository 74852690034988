import * as React from "react";
import Section from "@components/Section";
import SEO from "@components/SEO";
import Layout from "@components/Layout";
import SocialLinks from "@components/SocialLinks";
import Subscription from "@components/Subscription";

import { useColorMode } from "theme-ui";
import { Box } from "@rebass/grid";
import { graphql, StaticQuery } from "gatsby";

import CallToAction from "../components/funnel/CallToAction";
import ProductCoverImpl from "../@narative/gatsby-theme-novela/sections/cover/ProductCoverImpl";
import IThemeProviderProps from "../components/types/IThemeProviderProps";
import IRouteProps from "../components/types/IRouteProps";
import { IBlockProps } from "../components/types/IBlockProps";
import MainCoverImpl from "../@narative/gatsby-theme-novela/sections/cover/MainCoverImpl";

interface IDesignProps extends IRouteProps, IThemeProviderProps {}

interface IDesignState {
    covers?: IBlockProps;
}

const contentQuery = graphql`
    query {
        allSite {
            edges {
                node {
                    siteMetadata {
                        name
                        social {
                            url
                            name
                        }
                    }
                }
            }
        }
    }
`;

class Designs extends React.Component<IDesignProps, IDesignState> {
    public componentDidMount() {
        console.log("[Page ID]: ", this.props.id);

        let baseAwsS3Uri =
            "https://s3.ca-central-1.amazonaws.com/static-nuvahs-edge-node-ca-central.repo.host/data-cdn";

        let coverUri = `${baseAwsS3Uri}/designs_${this.props.id}.json`;

        fetch(coverUri).then((res) => {
            res.json().then((v) => {
                this.setState({ covers: v });
            });
        });
    }

    public render() {
        return (
            (this.state?.covers && (
                <Layout>
                    <MainCoverImpl renderContent={false} />

                    {this.state.covers.containers.map((m) => {
                        return m.content.map((c) => {
                            return (
                                <ProductCoverImpl
                                    content={{
                                        title: [c.title.toString()],
                                        subtitle: "",
                                        text: <>{c.text}</>,
                                    }}
                                    media={{
                                        placeholder: c.media[0].url,
                                    }}
                                    route={c.route}
                                />
                            );
                        });
                    })}

                    <StaticQuery
                        query={contentQuery}
                        render={(data) => {
                            const {
                                social,
                            } = data.allSite.edges[0].node.siteMetadata;

                            return (
                                <>
                                    <SEO
                                        pathname={`/product/${this.props.id ??
                                            ""}`}
                                    />

                                    <Section>
                                        <CallToAction />

                                        <Subscription />

                                        <Box
                                            mt={[2, 3, 5]}
                                            width={1}
                                            style={{
                                                textAlign: "center",
                                                transform: "scale(1.5)",
                                            }}
                                        >
                                            <SocialLinks links={social} />
                                        </Box>
                                    </Section>
                                </>
                            );
                        }}
                    />
                </Layout>
            )) || <>{"Loading..."}</>
        );
    }
}

const StateWrapper = (component) => (props) => {
    const colorMode = useColorMode();
    const isDark = colorMode[0] === "dark";
    const fill = isDark ? "#fff" : "#000";

    return <Designs {...props} fill={fill} />;
};

export default StateWrapper(Designs);
